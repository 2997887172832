@font-face {
  font-family: 'FSecureOffice';
  src: url(../fonts/Dazzed-Regular.9aeb1fd7.woff2);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FSecureOffice';
  src: url(../fonts/Dazzed-RegularItalic.4e1f1913.woff2);
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'FSecureOffice';
  src: url(../fonts/Dazzed-Bold.3786436f.woff2);
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'FSecureOffice';
  src: url(../fonts/Dazzed-BoldItalic.b8a39cd5.woff2);
  font-weight: bold;
  font-style: italic;
}
